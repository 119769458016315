import React from "react"
import { graphql } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"


import BlogfeedItem from '../components/blogfeed-item'
import readingTime from 'reading-time';
import get from 'lodash/get'

class Stories extends React.Component {
  render() {
    const { data } = this.props
/*     const siteTitle = data.site.siteMetadata.title */
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location}>
        <SEO
          title="Stories"
          description="Stories I've written"
          keywords={[`stories`, `blog`, `gatsby`, `javascript`, `react`, `docker`]}
        />
        <div className="blog-wrapper">
          <h2 class="is-hidden">Stories</h2>
          {
            posts.map(({ node }) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug
          let stats = readingTime(node.html)
          return (
          <BlogfeedItem title={title} 
          date={node.frontmatter.date} 
          description={node.frontmatter.description ? node.frontmatter.description : node.excerpt  } 
          slug={node.fields.slug} 
          key={node.fields.slug} 
          rt={stats.text} 
          tags={node.frontmatter.tags}>
          </BlogfeedItem>)
            })
          }
        </div>
      </Layout> 
    )
  }
}

export default Stories

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC },
      filter: {
        frontmatter: {
          published: {
            ne: false
          }
          story: {
            eq: true
          }
        }
      }) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
