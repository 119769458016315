import React from 'react'
import Link from 'gatsby-link';

const BlogfeedItem = (props) => {
  let taggies = props.tags.join(", ")
  return (
    <div className="section blogpost-feed">
      <div className="mb-3">
      {/* <nav className="level is-mobile">
        <div className="level-left">
          <p className="level-item subtitle has-text-left level-item subtitle has-text-right">
            {props.date}
          </p>
        </div>
        <div className="level-right">
          <p className="level-item subtitle has-text-left level-item subtitle has-text-right">
            {props.rt}
          </p>
        </div>
        
      {props.tags.forEach((tag) => {
          return `<h3>EVO ME ${tag}<h3>`
        })}
      </nav> */}
        <Link className="block truncate text-myblue" to={props.slug}>{props.title}</Link>
        <p className="text-gray-900 mt-1 leading-5">{props.description}</p>
        {/* <p class="blogpost-categories" dangerouslySetInnerHTML={{ __html: taggies.split(',').map(item => `<a href="/tags/${item.replace(/\s/g, '')}">${item}</a>`)}}></p>
        <p class="blogpost-date">{props.date}</p> */}
      </div>
    </div>
    )
} 

export default BlogfeedItem